import LNtests from "@vendor/regex";
import licenzo from '@vendor/licenzo';
import fetch from "isomorphic-unfetch";
import {testInit, logPageView} from "@vendor/analytics";
import {mapGenderStr, stateLookup, getTokenFromCookie, formatCoverageValue, isUsaState} from "@utilities";
import {addDays, differenceInYears, format} from "date-fns";
import {CARRIER_IMAGE_PATH} from "@constants";
import useFormatText from "@hooks/useFormatText";
import {phone} from "@config/site";
import SVG from "@components/general/svg";

let index = 0;

module.exports = {

    updateAnalytics(step) {
        testInit();
        logPageView(step);
    },


    log: (...args) => {
        if (![process.env.AUTO_LIVE_ENV_HOSTNAME, process.env.AUTO_SANDBOX_ENV_HOSTNAME].includes(window.location.hostname)) {
            console.log(...args);
        }
    },


    capitalize: function (s) {
        return typeof s !== 'string' ? '' : s.charAt(0).toUpperCase() + s.slice(1)
    },


    makeBool: function (val) {
        let result;
        if (typeof val === 'string') {
            result = (val.toLowerCase() === 'yes' || val.toLowerCase() === '1' || val.toLowerCase() === 'true');
        } else {
            result = (val);
            result = (typeof result === "undefined") ? false : result;
        }
        return result;
    },


    /*
     * @deprecated - please use `ClientApp/resources/utilities/step-lookup`
     */
    getStepName: function (step) {
        let stepMapping = {
            "0": "get-address",
            "1": "initial-details",
            "2": "drivers",
            "3": "vehicles",
            "4": "current-or-recent-coverage",
            "5": "contact-details",
            "6": "quote-selection",
            "7": "carrier-terms-and-conditions",
            "8": "payment",
            "9": "success"
        };
        return stepMapping[step];
    },


    /*
     * @deprecated - please use `ClientApp/resources/utilities/step-lookup`
     */
    getStepEnum: function (step) {
        let stepMapping = {
            "0": "GetAddress",
            "1": "InitialUserDetails",
            "2": "Drivers",
            "3": "Vehicles",
            "4": "CurrentOrRecentCoverage",
            "5": "ContactDetails",
            "6": "QuoteSelection",
            "7": "CarrierTermsConditions",
            "8": "Payment",
            "9": "Success"
        };
        return stepMapping[step];
    },


    /*
     * @deprecated - please use `ClientApp/resources/utilities/step-lookup`
     */
    getStepDisplayName: function (step) {
        let stepMapping = {
            "1": "Your Details",
            "2": "Driver Details",
            "3": "Vehicle Details",
            "4": "Current Coverage",
            "5": "Contact Details",
            "6": "Quotes",
            "7": " Terms and Conditions",
            "8": "Success"
        };
        return stepMapping[step];
    },

    /*
     * @deprecated - please use `ClientApp/resources/utilities/step-lookup`
     */
    getInterstitialMessage: function (step) {
        let stepMessages = [
            {
                step: "",
                id: "loadingInterstitialMessage",
                message: "Loading the form"
            },
            {
                step: "0",
                id: "loadingYourDetailsInterstitialMessage",
                message: "Looking up Your Details"
            },
            {
                step: "1",
                id: "loadingDriverDetailsInterstitialMessage",
                message: "Looking up Driver Details"
            },
            {
                step: "2",
                id: "loadingVehicleDetailsInterstitialMessage",
                message: "Looking up Vehicle Details"
            },
            {
                step: "3",
                id: "loadingCurrentCoverageInterstitialMessage",
                message: "Looking up Current Coverage"
            },
            {
                step: "4",
                id: "loadingContactDetailsInterstitialMessage",
                message: "Looking up Contact Details"
            },
            {
                step: "5",
                id: "loadingQuotesInterstitialMessage",
                message: "We are contacting our insurance partners to get your quotes. The best things take time, so please bear with us.",
            },
            {
                step: "6",
                id: "loadingPreferredQuoteInterstitialMessage",
                message: "Processing your preferred quote"
            },
            {
                step: "7",
                id: "loadingPaymentInterstitialMessage1",
                message: "Processing your preferred quote"
            },
            {
                step: "8",
                id: "loadingPaymentInterstitialMessage1",
                message: "Processing your payment"
            },
            {
                step: "9",
                id: "loadingPaymentInterstitialMessage2",
                message: "Processing your payment"
            },
            {
                step: "10",
                id: "updatingDetailsInterstitialMessage",
                message: "Updating your details"
            }
        ];
        return stepMessages.filter(i => {
            return i.step === step.toString()
        })[0];
    },


    /*
     * @deprecated - please use `ClientApp/resources/utilities/state-lookup`
     */
    getStateNameFromEnum: function (stateEnum) {
        if (!stateEnum) {
            return;
        }

        let stateMapping = {
            "Alaska": 10,
            "Alabama": 20,
            "Arkansas": 30,
            "Arizona": 40,
            "California": 50,
            "Colorado": 60,
            "Connecticut": 70,
            "Delaware": 80,
            "DistrictOfColumbia": 90,
            "Florida": 100,
            "Georgia": 110,
            "Hawaii": 120,
            "Iowa": 130,
            "Idaho": 140,
            "Illinois": 150,
            "Indiana": 160,
            "Kansas": 170,
            "Kentucky": 180,
            "Louisiana": 190,
            "Massachusetts": 200,
            "Maryland": 210,
            "Maine": 220,
            "Michigan": 230,
            "Minnesota": 240,
            "Missouri": 250,
            "Mississippi": 260,
            "Montana": 270,
            "NorthCarolina": 280,
            "NorthDakota": 290,
            "Nebraska": 300,
            "NewHampshire": 310,
            "NewJersey": 320,
            "NewMexico": 330,
            "Nevada": 340,
            "NewYork": 350,
            "Ohio": 360,
            "Oklahoma": 370,
            "Oregon": 380,
            "Pennsylvania": 390,
            "RhodeIsland": 400,
            "SouthCarolina": 410,
            "SouthDakota": 420,
            "Tennessee": 430,
            "Texas": 440,
            "Utah": 450,
            "Virginia": 460,
            "Vermont": 470,
            "Washington": 480,
            "Wisconsin": 490,
            "WestVirginia": 500,
            "Wyoming": 510,
            "Canada":  520,
            "Mexico": 530,
            "International": 530,
            "Other": 0
        }
        return Object.keys(stateMapping).find(key => stateMapping[key] === stateEnum).toLowerCase();
    },

    /*
     * @deprecated - please use `ClientApp/resources/utilities/state-lookup`
     */
    getStateNameType: function (state) {
        if (!state) {
            return;
        }
        let stateMappping = {
            "alaska": "AK",
            "alabama": "AL",
            "arkansas": "AR",
            "arizona": "AZ",
            "california": "CA",
            "colorado": "CO",
            "connecticut": "CT",
            "delaware": "DE",
            "districtofcolumbia": "DC",
            "florida": "FL",
            "georgia": "GA",
            "hawaii": "HI",
            "iowa": "IA",
            "idaho": "ID",
            "illinois": "IL",
            "indiana": "IN",
            "kansas": "KS",
            "kentucky": "KY",
            "louisiana": "LA",
            "massachusetts": "MA",
            "maryland": "MD",
            "maine": "ME",
            "michigan": "MI",
            "minnesota": "MN",
            "missouri": "MO",
            "mississippi": "MS",
            "montana": "MT",
            "northcarolina": "NC",
            "northdakota": "ND",
            "nebraska": "NE",
            "newhampshire": "NH",
            "newjersey": "NJ",
            "newmexico": "NM",
            "nevada": "NV",
            "newyork": "NY",
            "ohio": "OH",
            "oklahoma": "OK",
            "oregon": "OR",
            "pennsylvania": "PA",
            "rhodeisland": "RI",
            "southcarolina": "SC",
            "southdakota": "SD",
            "tennessee": "TN",
            "texas": "TX",
            "utah": "UT",
            "virginia": "VA",
            "vermont": "VT",
            "washington": "WA",
            "wisconsin": "WI",
            "westvirginia": "WV",
            "wyoming": "WY",
            "canada": "CN",
            "mexico": "MX",
            "international": "IT",
            "other": "ZZ"
        };
        return stateMappping[state.replace(/\s/g, '').toLowerCase()];
    },

    /*
     * @deprecated - please use `ClientApp/resources/utilities/state-lookup`
     */
    getDriverLicenseStateTypeForAPI: function (state) {
        if (!state) {
            return;
        }
        let stateMappping = {
            "alaska": "Alaska",
            "alabama": "Alabama",
            "arkansas": "Arkansas",
            "arizona": "Arizona",
            "california": "California",
            "colorado": "Colorado",
            "connecticut": "Connecticut",
            "delaware": "Delaware",
            "districtofcolumbia": "DistrictOfColumbia",
            "florida": "Florida",
            "georgia": "Georgia",
            "hawaii": "Hawaii",
            "iowa": "Iowa",
            "idaho": "Idaho",
            "illinois": "Illinois",
            "indiana": "Indiana",
            "kansas": "Kansas",
            "kentucky": "Kentucky",
            "louisiana": "Louisiana",
            "massachusetts": "Massachusetts",
            "maryland": "Maryland",
            "maine": "Maine",
            "michigan": "Michigan",
            "minnesota": "Minnesota",
            "missouri": "Missouri",
            "mississippi": "Mississippi",
            "montana": "Montana",
            "northcarolina": "NorthCarolina",
            "northdakota": "NorthDakota",
            "nebraska": "Nebraska",
            "newhampshire": "NewHampshire",
            "newjersey": "NewJersey",
            "newmexico": "NewMexico",
            "nevada": "Nevada",
            "newyork": "NewYork",
            "ohio": "Ohio",
            "oklahoma": "Oklahoma",
            "oregon": "Oregon",
            "pennsylvania": "Pennsylvania",
            "rhodeisland": "RhodeIsland",
            "southcarolina": "SouthCarolina",
            "southdakota": "SouthDakota",
            "tennessee": "Tennessee",
            "texas": "Texas",
            "utah": "Utah",
            "virginia": "Virginia",
            "vermont": "Vermont",
            "washington": "Washington",
            "wisconsin": "Wisconsin",
            "westvirginia": "WestVirginia",
            "wyoming": "Wyoming",
            "canada": "Canada",
            "mexico": "Mexico",
            "international": "International"
        };
        return stateMappping[state.replace(/\s/g, '').toLowerCase()];
    },

    /*
     * @deprecated - please use `ClientApp/resources/utilities/state-lookup`
     */
    getStateType: function (state) {
        if (!state) {
            return;
        }
        let stateMappping = {
            "AK": "Alaska",
            "AL": "Alabama",
            "AR": "Arkansas",
            "AZ": "Arizona",
            "CA": "California",
            "CO": "Colorado",
            "CT": "Connecticut",
            "DE": "Delaware",
            "DC": "DistrictOfColumbia",
            "FL": "Florida",
            "GA": "Georgia",
            "HI": "Hawaii",
            "IA": "Iowa",
            "ID": "Idaho",
            "IL": "Illinois",
            "IN": "Indiana",
            "KS": "Kansas",
            "KY": "Kentucky",
            "LA": "Louisiana",
            "MA": "Massachusetts",
            "MD": "Maryland",
            "ME": "Maine",
            "MI": "Michigan",
            "MN": "Minnesota",
            "MO": "Missouri",
            "MS": "Mississippi",
            "MT": "Montana",
            "NC": "NorthCarolina",
            "ND": "NorthDakota",
            "NE": "Nebraska",
            "NH": "NewHampshire",
            "NJ": "NewJersey",
            "NM": "NewMexico",
            "NV": "Nevada",
            "NY": "NewYork",
            "OH": "Ohio",
            "OK": "Oklahoma",
            "OR": "Oregon",
            "PA": "Pennsylvania",
            "RI": "RhodeIsland",
            "SC": "SouthCarolina",
            "SD": "SouthDakota",
            "TN": "Tennessee",
            "TX": "Texas",
            "UT": "Utah",
            "VA": "Virginia",
            "VT": "Vermont",
            "WA": "Washington",
            "WI": "Wisconsin",
            "WV": "WestVirginia",
            "WY": "Wyoming",
            "CN": "Canada",
            "MX": "Mexico",
            "IT": "International",
            "ZZ": "Other"
        };
        return stateMappping[state];
    },


    getInitialDesiredPolicyEffectiveDate: function (daysToStart) {
        return addDays(new Date(), daysToStart);
    },


    queryParams: function (params) {
        return Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
    },


    haveHouseNumber: function (address) {
        let chk = false;
        if (typeof address === "object") {
            if (typeof address.street_number === "string") {
                chk = true;
            }
        }

        return chk;
    },


    isValidAddress: function (address) {
        let isValid = true;
        var addressObject = this.parseAddressObj(address, null);

        if (!addressObject.StreetName || !addressObject.HouseNumber || !addressObject.City || !addressObject.ConstituentState || !addressObject.PostalCode) {
            isValid = false;
        }

        return isValid;
    },


    addressSelected: function (address) {
        let chk = false;
        if (typeof address === "object") {
            chk = true;
        }

        return chk;
    },


    parseAddress: function (addressString) {
        let address = addressString.split(', ');
        let street = address[0];
        let city = address[1];
        let streetName, HouseNumber;

        if (address.indexOf(' ')) {
            streetName = street.trim().split(' ');
            HouseNumber = streetName[0];
            streetName.shift();
        }

        let temp;
        if (address[2] && address[2].length > 2) {
            temp = address[2].split(' ');
            address[2] = temp[0];
        }
        let state = this.getStateType(address[2]);

        let obj = {
            Street1: street,
            Street2: '',
            City: city,
            StateType: state
        };

        if (streetName) {
            obj.StreetName = streetName.join(' ');
            obj.HouseNumber = HouseNumber;
        }

        if (temp) {
            obj.PostalCode = temp[1];
        }

        // this.log(obj);
        return obj;
    },


    concatAddressObj: function (addressObj, apartment, includeApt = true) {
        if (typeof addressObj === "object") {
            let street = addressObj.route;

            let city = "locality" in addressObj ? addressObj.locality : "neighborhood" in addressObj ? addressObj.neighborhood : null;
            let state = addressObj.administrative_area_level_1.replace(/\s/g, '');
            if (state.length === 2) {
                state = this.getStateType(state);
            }

            let postCode = addressObj.postal_code;
            let house_number = addressObj.street_number;

            let string = house_number + ' ' + street + ', ' + city + ', ' + state + ', ' + postCode;

            if (apartment && includeApt) {
                string = 'Apt ' + apartment + ', ' + string;
            }

            // this.log(obj);
            return string;
        }
    },


    parseAddressObj: function (addressObj, apartment) {

        if (typeof addressObj === "object") {
            let street = addressObj.route;

            let city = "locality" in addressObj ? addressObj.locality : "neighborhood" in addressObj ? addressObj.neighborhood : null;
            let state = addressObj.administrative_area_level_1.replace(/\s/g, '');
            let county = addressObj.administrative_area_level_2;
            if (state.length === 2) {
                state = this.getStateType(state);
            }

            let postCode = addressObj.postal_code;
            let house_number = addressObj.street_number;

            return {
                Street1: house_number + ' ' + street,
                StreetName: street,
                HouseNumber: house_number,
                Apartment: apartment,
                Street2: '',
                City: city,
                County: county,
                ConstituentState: state,
                PostalCode: postCode
            };
        } else {
            return this.parseAddress(addressObj);
        }
    },


    mapGenderLetter: function (str) {
        let values = {
            10: 'M',
            20: 'F'
        };

        return values[str];
    },


    getInsurerLogo(insurerId, size = false) {
        let logo = this.getInsurerLogoName(insurerId);
        return CARRIER_IMAGE_PATH + logo + (size ? '--' + size : '') + '--color.png';
    },


    getInsurerLogoName(InsurerID) {
        let logo;

        switch (parseInt(InsurerID)) {

            case 404:
                logo = 'stillwater';
                break;

            case 9:
            case 25:
                logo = 'progressive';
                break;

            case 59:
                logo = 'aaa';
                break;

            case 391:
                logo = 'allstate';
                break;

            case 105:
            case 169:
                logo = 'auto-owners';
                break;

            case 52:
                logo = 'bristol-west';
                break;

            case 7:
            case 479:
                logo = 'foremost';
                break;

            case 2:
                logo = 'hartford';
                break;

            case 224:
                logo = 'infinity';
                break;

            case 58:
                logo = 'kemper';
                break;

            case 87:
                logo = 'mercury';
                break;

            case 201:
                logo = 'national-general';
                break;

            case 99:
            case 348:
            case 447:
                logo = 'nationwide';
                break;

            case 3:
            case 53:
            case 35:
                logo = 'safeco';
                break;

            case 347:
            case 334:
                logo = 'dairyland';
                break;
            
            case 578:
                logo = 'the-general';
                break;

            case 13:
            case 999:
                logo = 'travelers';
                break;

            case 9999:
                logo = 'fake9999';
                break;

            case 9998:
                logo = 'fake9998';
                break;

            case 9997:
                logo = 'fake9997';
                break;

            case 9996:
                logo = 'fake9996';
                break;

            case 9995:
                logo = 'fake9995';
                break;

            default:
                logo = '';
        }
        return logo
    },


    formatMoney(amount, currency = false) {
        if (!amount) {
            return "-";
        }
        try {
            const decimalCount = 2;

            const negativeSign = amount < 0 ? "-" : "";
            const currencySign = currency ? "$" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + currencySign + (j ? i.substr(0, j) + "," : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + ",") + (decimalCount ? "." + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            this.log(e);
        }
    },


    valid_credit_card(value) {
        // Accept only digits, dashes or spaces
        if (/[^0-9-\s]+/.test(value)) return false;

        // The Luhn Algorithm. It's so pretty.
        let nCheck = 0, bEven = false;
        value = value.replace(/\D/g, "");

        for (var n = value.length - 1; n >= 0; n--) {
            var cDigit = value.charAt(n),
                nDigit = parseInt(cDigit, 10);

            if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

            nCheck += nDigit;
            bEven = !bEven;
        }

        return (nCheck % 10) === 0;
    },


    valid_cvv(value) {
        return value.length === 3 || value.length === 4;

    },


    moneyMarkUp(string) {

        let newString = string;
        if (string.toString().search('.') > -1) {
            let temp = string.toString().split('.');
            newString = temp[0] + "<span class='units'>." + temp[1] + "</span>";
        }

        return newString;
    },


    calculateAge(date) {
        return differenceInYears(new Date(), new Date(date))
    },


    paymentPlan(IsPayInFull, NumberOfPayments) {
        let plan;

        switch (IsPayInFull) {
            case true:
                plan = 'Full';
                break;

            case false:
                plan = 'Installments';
                break
        }

        if (plan === 'Installments' && NumberOfPayments === 1) {
            plan = 'Unspecified';
        }


        return plan;
    },


    GetCardType(number, cards = []) {

        let re = "";

        number = number.replace(/\s/g, '');

        const check = (card) => {
            return cards.includes(card) || cards.length === 0;
        };

        // visa
        if (check("Visa")) {
            re = new RegExp("^4");
            if (number.match(re) !== null)
                return "Visa";
        }

        // Mastercard
        // Updated for Mastercard 2017 BINs expansion
        if (check("Visa")) {
            if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
                return "Mastercard";
        }

        // AMEX
        if (check("Amex")) {
            re = new RegExp("^3[47]");
            if (number.match(re) !== null)
                return "AMEX";
        }

        // Discover
        if (check("Discover")) {
            re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
            if (number.match(re) !== null)
                return "Discover";
        }

        // Diners
        if (check("Diners")) {
            re = new RegExp("^36");
            if (number.match(re) !== null)
                return "Diners";
        }

        // Diners - Carte Blanche
        if (check("DinersCarteBlanche")) {
            re = new RegExp("^30[0-5]");
            if (number.match(re) !== null)
                return "Diners - Carte Blanche";
        }

        // JCB
        if (check("JCB")) {
            re = new RegExp("^35(2[89]|[3-8][0-9])");
            if (number.match(re) !== null)
                return "JCB";
        }

        // Visa Electron
        if (check("VisaElectron")) {
            re = new RegExp("^(4026|417500|4508|4844|491([37]))");
            if (number.match(re) !== null)
                return "Visa Electron";
        }

        return "";
    },


    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },


    validateDriversLicense(state, dl) {
        if (!state || dl === '') {
            return {
                status: 0,
                message: `State required.`,
            };
        }
        if (state.length === 2) {
            state = state.toUpperCase();
        } else {
            state = stateLookup('label', state, 'abv');
        }

        if (!state) {
            return {
                status: 0,
                message: `State required.`,
            };
        }

        if (dl.match(/^\*+\d{4}$/)) {
            return {
                status: 1,
                message: `License is masked`,
            }
        }


        if (!isUsaState(stateLookup('abv', state, 'state'))) {
            return {
                status: 1,
                message: `License is Valid`,
            }
        }
        const failedTests = LNtests[state].filter(format => !format.regex.test(dl));
        if (failedTests.length === LNtests[state].length) {
            const whyItFailed = failedTests.map(reason => reason.description).join(' OR ');
            return {
                status: 0,
                message: `Required for ${state}: ${whyItFailed}`,
            };
        }
        return {
            status: 1,
            message: `License is Valid`,
        }
    },


    isBoolString(n) {

        let results = {
            'no': 0,
            'yes': 1,
            'true': 1,
            'false': 0,
        };


        if (typeof n === 'string') {
            n = n.toLowerCase();
        }
        let test = results[n];

        return (test === undefined) ? 'string' : test;
    },


    getPolicyPeriodDetails(data) {
        return [
            {
                "label": "From",
                "value": `${format(new Date(data.PolicyStartDate), "MM-dd-yyyy")} @ 12:01am`,
                "hook": "th_PolicyStartDate"
            },
            {
                "label": "To",
                "value": `${format(new Date(data.PolicyExpirationDate), "MM-dd-yyyy")} @ 12:01am`,
                "hook": "th_PolicyExpirationDate"
            },
            {
                "label": "Date Prepared",
                "value": `${format(new Date(data.CreateDate), "MM-dd-yyyy")}`,
                "hook": "th_PolicyCreatedDate"
            }
        ]
    },


    getDriversAndCars(appTransformedData) {
        let simpleObj = [];
        if (typeof appTransformedData.Drivers) {
            for (const i in appTransformedData.Drivers) {
                let driver = {};
                const middleInitial = appTransformedData.Drivers[i].MiddleName ? appTransformedData.Drivers[i].MiddleName.charAt(0).toUpperCase() : "";
                driver.name = `${appTransformedData.Drivers[i].FirstName} ${middleInitial} ${appTransformedData.Drivers[i].LastName}${appTransformedData.Drivers[i].RatingType === "Excluded" ? " (Excluded)" : ""}`;
                driver.cars = [];

                for (const v in appTransformedData.Vehicles) {
                    if (appTransformedData.Vehicles[v].PrimaryDriver === appTransformedData.Drivers[i].ReferenceId) {
                        let year = appTransformedData.Vehicles[v].ModelYear ?? appTransformedData.Vehicles[v].Year;
                        let make = appTransformedData.Vehicles[v].Manufacturer ?? appTransformedData.Vehicles[v].Make;

                        driver.cars.push(year + ' ' + make + ' ' + appTransformedData.Vehicles[v].Model);
                    }
                }

                simpleObj.push(driver);
            }
        }

        return simpleObj;
    },


    getPolicyCoverageDetails(quoteCoverDetails) {
        const coverageDetails = [{
            "label": "Bodily Injury/Liability (BI)",
            "value": formatCoverageValue(quoteCoverDetails.BodilyInjury),
            "hook": "th_BodilyInjury"
        },{
            "label": "Property Damage",
            "value": formatCoverageValue(quoteCoverDetails.PropertyDamage),
            "hook": "th_PolicyPropertyDamage"
        }, {
            "label": "Uninsured/Underinsured Motorist Non-Stacked",
            "value": formatCoverageValue(quoteCoverDetails.UninsuredUnderinsuredMotorist),
            "hook": "th_PolicyUninsuredUnderinsuredMotorist"
        }];

        if (quoteCoverDetails.ShowUmpd && quoteCoverDetails.UninsuredUnderinsuredMotoristPropertyDamageLiabilityLimit !== null) {
            coverageDetails.push({
                "label": "Uninsured/Underinsured Motorist Property Damage Liability Limit",
                "value": formatCoverageValue(quoteCoverDetails.UninsuredUnderinsuredMotoristPropertyDamageLiabilityLimit),
                "hook": "th_PolicyUninsuredMotoristPropertyDamageLiabilityLimit"
            });
        }

        if (quoteCoverDetails.ShowUmpdd && quoteCoverDetails.UninsuredMotoristPropertyDamageDeductible !== null) {
            coverageDetails.push({
                "label": "Uninsured/Underinsured Motorist Property Damage Deductible",
                "value": formatCoverageValue(quoteCoverDetails.UninsuredMotoristPropertyDamageDeductible),
                "hook": "th_PolicyUninsuredMotoristPropertyDamageDeductible"
            });
        }

        coverageDetails.push({
            "label": "Medical Payments",
            "value": formatCoverageValue(quoteCoverDetails.MedicalPayments),
            "hook": "th_PolicyMedicalPayments"
        });

        if (quoteCoverDetails.PersonalInjuryProtection !== null) {
            coverageDetails.push({
                "label": quoteCoverDetails.PersonalInjuryProtection.DisplayLabel,
                "value": formatCoverageValue(quoteCoverDetails.PersonalInjuryProtection.DisplayValue),
                "hook": "th_PolicyPersonalInjuryProtection"
            });
        }

        return coverageDetails;

    },


    getQuoteSummary(quoteSummary, carrierId, additionalPaymentData) {

        const isProgressive = 9;
        const isNationwide = 99;

        carrierId = Number(carrierId);

        if (carrierId === isProgressive) {
            const {pascalCase} = useFormatText();
            const {
                Summary,
                Vehicles,
                Drivers
            } = quoteSummary;

            return (
                <>
                    <p className={"fontMedium my-20"}>We have calculated your rate using the additional details below,
                        please confirm that all details listed are correct before continuing. If you would like to
                        change anything call us on {phone}.</p>
                    <h4 className={"mb-3"}>Summary</h4>
                    <div className="sub-section">
                        {Summary.map((item, i) => {
                            return (
                                <div key={i} className="inner-section-details">
                                    <div className="label">{item.Label}</div>
                                    <div data-cy={`th_PolicyDisclaimer`} className="detail">{item.Value}</div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="inner-section-divider"/>

                    <div className="sub-section">
                        <div className={`inner-section-details--grid ${Drivers.length === 1 ? "single" : ""}`}>
                            {Drivers.map((driver, i) => {
                                const thisDriver = [...driver];

                                const titleDetailsIndex = thisDriver.findIndex(item => item.Label === "Driver");
                                let title = `Driver ${i + 1}`;
                                if (titleDetailsIndex >= 0) {
                                    const titleDetails = thisDriver.splice(titleDetailsIndex, 1);
                                    title = titleDetails[0].Value;
                                }

                                return (
                                    <div key={i} className={"list"}>
                                        <dl data-cy={`th_PolicyDisclaimerDriver_${i}`}>
                                            <dt className={"mb-3"}>{title}</dt>
                                            {thisDriver.map((item, j) => {
                                                    return (
                                                        <dd key={j} className="inner-section-details">
                                                            <div className="label">{item.Label}</div>
                                                            <div
                                                                data-cy={`th_PolicyDisclaimerDriver_${i}_${pascalCase(item.Label)}`}
                                                                className="detail">{item.Value}</div>
                                                        </dd>
                                                    )
                                                }
                                            )}
                                        </dl>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="inner-section-divider"/>

                    <div className="sub-section">
                        <div className={`inner-section-details--grid ${Vehicles.length === 1 ? "single" : ""}`}>
                            {Vehicles.map((vehicle, i) => {
                                const thisVehicle = [...vehicle];
                                const titleDetailsIndex = thisVehicle.findIndex(item => item.Label === "Vehicle");
                                let title = `Vehicle ${i + 1}`;
                                if (titleDetailsIndex >= 0) {
                                    const titleDetails = thisVehicle.splice(titleDetailsIndex, 1);
                                    title = titleDetails[0].Value;
                                }

                                const driverPerformanceIndex = thisVehicle.findIndex(item => item.Label === "Driver performance monitoring");
                                let driverPerformanceLabel = false;
                                if (driverPerformanceIndex >= 0) {
                                    const performanceLabel = thisVehicle.splice(driverPerformanceIndex, 1);
                                    driverPerformanceLabel = <span className={"dangerDangerWillRobinson"}><SVG src={"warning-triangle-white"}/>{ performanceLabel[0].Value }</span> ;
                                }

                                return (
                                    <div key={i} className={"list"}>
                                        <dl data-cy={`th_PolicyDisclaimerVehicle_${i}`}>
                                            <dt className={"mb-3"}>{title}</dt>
                                            {thisVehicle.map((item, j) => {
                                                    return (
                                                        <dd key={j} className="inner-section-details">
                                                            <div className="label">{item.Label}</div>
                                                            <div
                                                                data-cy={`th_PolicyDisclaimerVehicle_${i}_${pascalCase(item.Label)}`}
                                                                className="detail">{item.Value}</div>
                                                        </dd>
                                                    )
                                                }
                                            )}
                                            {driverPerformanceLabel && (
                                                <dd className="inner-section-details">
                                                    <div className="label">Driver performance monitoring</div>
                                                    <div>{ driverPerformanceLabel }</div>
                                                </dd>
                                            )}
                                            {thisVehicle.length === 0 && (
                                                <dd>No assumptions for this vehicle</dd>
                                            )}
                                        </dl>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                </>

            )
        } else if (carrierId === isNationwide) {
            let legalDisclosuresListArray = [];

            if (additionalPaymentData.length > 0) {
                let legalDisclosuresForBulletList = additionalPaymentData.filter(legalDisclosure => {
                    return (legalDisclosure.Name !== 'RBC Form Verbiage' &&
                        legalDisclosure.Name !== 'RBC Payment Authorization and Link' &&
                        legalDisclosure.Name !== 'ESDDA' &&
                        legalDisclosure.Name !== 'Privacy, Credit, T&C')
                });
                legalDisclosuresListArray = legalDisclosuresForBulletList.map((b) => <span
                    key={b.Name}>{b.Description}</span>)
            }

            return (
                <div className={'paymentDisclaimer'}>
                    <div className="sub-section">
                        {legalDisclosuresListArray.map((item, i) => {
                            return (
                                <div key={i} className="inner-section-details">
                                    <div>{item}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            );
        }
    },


    getVehicleCoverageDetails(vehicles) {
        return vehicles.map((vehicle) => {
            return {
                details: [
                    {
                        "label": "Comprehensive Deductible",
                        "value": vehicle.Comprehensive,
                        "hook": "th_PolicyComprehensiveDeductible"
                    },
                    {
                        "label": "Collision Deductible",
                        "value": vehicle.Collision,
                        "hook": "th_PolicyCollisionDeductible"
                    },
                    {
                        "label": "Rental Reimbursement",
                        "value": vehicle.Rental,
                        "hook": "th_PolicyRentalReimbursement"
                    },
                    {
                        "label": "Towing & Labour (TL)",
                        "value": vehicle.TowingPlusLabor,
                        "hook": "th_PolicyTowingPlusLabor"
                    }
                ],
                vin: vehicle.Vin,
                title: `${vehicle.ModelYear} ${vehicle.Manufacturer} ${vehicle.Model}`
            }
        })
    },

    pageUnload(event) {
        event.returnValue = "Are you sure you want to leave? Any information entered will be lost.";
    },


    getFLLicenceNo(FName, MName, LName, dobM, dobD, dobY, s) {
        let licenceGen = new licenzo();
        s = (typeof s === "string") ? mapGenderStr(s) : this.mapGenderLetter(s);
        return licenceGen.fl.generate(FName, MName, LName, dobM, dobD, '' + dobY + '', s);
    },

    
    async isValidGoogleMapsAddress(addressData) {
        const {
            StreetAddress,
            City,
            County,
            Apartment,
            ConstituentState,
            PostalCode
        } = addressData[0];

        if (!StreetAddress) {
            return {isValid: false}
        }

        const fullFormat = `${StreetAddress} ${City} ${County} ${ConstituentState} ${PostalCode}`;
        var geocoder = new google.maps.Geocoder();
        const {results} = await geocoder.geocode({
            'address': fullFormat,
            componentRestrictions: {country: "us"}
        });

        if (results.length > 0) {
            const addressComponents = {};

            let hasStreetName = false;

            for (let i = 0; i < results[0].address_components.length; i++) {
                const addressComponent = results[0].address_components[i];
                let addressType = addressComponent.types[0];
                if (addressType === 'route') {// Use the long form street name (e.g Avenue instead of Ave)
                    hasStreetName = true;
                    addressComponents[addressType] = addressComponent.long_name;
                } else {
                    addressComponents[addressType] = addressComponent.short_name;
                }
            }

            // No street name is present, so fail out.
            if (!hasStreetName) {
                return {isValid: false}
            }

            const streetNumber = addressComponents['street_number'] ? `${addressComponents['street_number']}` : '';
            const verifiedStreetAddress = `${streetNumber} ${addressComponents['route']}`;
            const verifiedCity = addressComponents['locality'] ?? addressComponents['neighborhood'];
            const verifiedCounty = addressComponents['administrative_area_level_2'];
            const verifiedState = stateLookup('abv', addressComponents['administrative_area_level_1'], 'state')
            const state = stateLookup('label', ConstituentState, 'state');
            const verifiedPostalCode = addressComponents.postal_code;

            if (
                verifiedStreetAddress.toLowerCase().includes(StreetAddress.toLowerCase()) &&
                verifiedCity.toLowerCase().includes(City.toLowerCase()) &&
                verifiedCounty.toLowerCase().includes(County.toLowerCase()) &&
                verifiedState === state &&
                verifiedPostalCode === PostalCode
            ) {
                return {
                    isValid: true,
                    address: {
                        Street1: verifiedStreetAddress,
                        StreetName: addressComponents['route'],
                        HouseNumber: streetNumber,
                        Apartment: Apartment,
                        City: verifiedCity,
                        County: verifiedCounty,
                        ConstituentState: verifiedState,
                        PostalCode: verifiedPostalCode
                    }
                }
            } else {
                let houseNumber = '', streetName = '';
                const street = StreetAddress?.split(' ');
                if (street && typeof street !== "undefined" && Number.isInteger(parseInt(street[0]))) {
                    [houseNumber, ...streetName] = street;
                } else {
                    streetName = street;
                }
                return {
                    isValid: false,
                    address: {
                        Street1: StreetAddress,
                        StreetName: streetName?.join(' '),
                        HouseNumber: houseNumber,
                        Apartment: Apartment,
                        City: City,
                        County: County,
                        ConstituentState: ConstituentState,
                        PostalCode: PostalCode
                    }
                }
            }
        } 

        return {isValid: false};
    },

    postToAPI(data, successCallBack, apiUrl) {

        let method = 'POST';
        let settings = {
            "async": true,
            "method": method
        };

        let url = process.env.NEXT_STATIC_RTU_API + apiUrl;

        settings.headers = {
            'Content-Type': 'application/json',
            'X-AUTO-CSRF-TOKEN': getTokenFromCookie('AUTO-CSRF-TOKEN'),
            'Accept': 'application/json'
        };

        settings.body = JSON.stringify(data);

        let statusCode;

        fetch(url, settings)
            .then((res) => {
                statusCode = res.status;
                return res.json();
            })
            .then(
                (result) => {
                    result.IsSuccessful = statusCode === 200;
                    if (successCallBack !== null) {
                        successCallBack(result);
                    }
                },
                (error) => {
                    this.log(error);
                }
            );

    },


    getNationwidePrivacyPolicy(successCallBack) {
        const settings = {
            "async": true
        };

        fetch(`${process.env.NEXT_STATIC_RTU_API}/api/legal-disclosures?carrierId=99&disclosureIds=8`, settings)
            .then(res => res.json())
            .then(
                (result) => {
                    successCallBack(result);
                },
                (error) => {
                    this.log(error);
                }
            );
    },


    convertToExternalLinks(data) {
        return ("" + data).replace(/<a\s+href=/gi, '<a target="_blank" rel="noopener noreferrer" href=');
    },


    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },


    calculateDriverDiscountResponse(DistantStudent, GoodStudent, DrivingCourse) {
        let result = 0;
        if (DistantStudent) {
            result += 1;
        }
        if (GoodStudent) {
            result += 2;
        }
        if (DrivingCourse) {
            result += 4;
        }
        return result;
    },


    shouldDiscountQuestionShow(question, apiValue) {

        let result = false;
        switch (true) {
            case (1 === apiValue && question === 'DistantStudent'):
                result = true;
                break;

            case (2 === apiValue && question === 'GoodStudent'):
                result = true;
                break;

            case (3 === apiValue && (question === 'GoodStudent' || question === 'DistantStudent')):
                result = true;
                break;

            case (4 === apiValue && question === 'DriverCourse'):
                result = true;
                break;

            case (5 === apiValue && (question === 'DistantStudent' || question === 'DriverCourse')):
                result = true;
                break;

            case (6 === apiValue && (question === 'GoodStudent' || question === 'DriverCourse')):
                result = true;
                break;

            case (7 === apiValue):
                result = true;
                break;
        }

        return result;
    },


    titleCase(str) {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    },


    getIndex(log = false) {
        index++;
        if (log) {
            this.log('index', index);
        }
        return index.toString();
    },


    EnvironmentIsProduction() {
        return window.location.hostname === 'www.simplyioa.com' || window.location.hostname === 'simplyioa.com';
    },


    isValidApplicationId(applicationId) {
        return applicationId !== undefined && applicationId !== null && applicationId !== '00000000-0000-0000-0000-000000000000'
    },

    useDrivingLicenseNumberAutoGeneration(dlState) {
        return ["Florida"].includes(dlState);
    },

    normalizeCoverageDetails(coverages) {
        coverages.BodilyInjury = parseInt(coverages.BodilyInjury);
        coverages.MedicalPayments = parseInt(coverages.MedicalPayments);
        coverages.OptionalBodilyInjury = coverages.OptionalBodilyInjury !== null && coverages.OptionalBodilyInjury !== undefined ? parseInt(coverages.OptionalBodilyInjury) : null;
        coverages.PropertyDamage = parseInt(coverages.PropertyDamage);
        coverages.UninsuredMotoristPropertyDamageDeductible = parseInt(coverages.UninsuredMotoristPropertyDamageDeductible);
        coverages.UninsuredMotoristPropertyDamageLiabilityLimit = parseInt(coverages.UninsuredMotoristPropertyDamageLiabilityLimit);
        coverages.UninsuredUnderinsuredMotorist = parseInt(coverages.UninsuredUnderinsuredMotorist);

        coverages.VehicleCoverRequirements.forEach((v) => {
            v.Collision = parseInt(v.Collision)
            v.Comprehensive = parseInt(v.Comprehensive)
            v.RentalLimit = parseInt(v.RentalLimit)
            v.TowingLimit = parseInt(v.TowingLimit)
        });
        return coverages
    },


    normalizeDriversData(drivers) {
        drivers.forEach((d) => {
            d.PrimaryResidence = parseInt(d.PrimaryResidence);
            d.EducationType = parseInt(d.EducationType);
            d.MaritalStatus = parseInt(d.MaritalStatus);
            d.DriverLicenseType = parseInt(d.DriverLicenseType);
            d.DriverLicenseState = stateLookup('state', d.DriverLicenseState.replace(/\s/g, ""), 'enum') ?? 0;
        })
        return drivers;
    }
}